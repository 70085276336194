// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/Article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-article-list-article-list-tsx": () => import("./../../../src/templates/ArticleList/ArticleList.tsx" /* webpackChunkName: "component---src-templates-article-list-article-list-tsx" */),
  "component---src-templates-calendar-page-calendar-page-tsx": () => import("./../../../src/templates/CalendarPage/CalendarPage.tsx" /* webpackChunkName: "component---src-templates-calendar-page-calendar-page-tsx" */),
  "component---src-templates-contact-contact-tsx": () => import("./../../../src/templates/Contact/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-contact-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/Home/Home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */)
}

